import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import MuiTextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { makeStyles, withStyles } from "@material-ui/core/styles"

import Seo from "../components/seo"
import Cloud from "../components/cloud"
import Layout from "../components/layout"
import FacebookIcon from "../images/facebook.svg"
import { CustomButton } from "../components/buttons"

const TextField = withStyles(theme => ({
  root: {
    "& label.Mui-focused": {
      // color: "green",
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 14px",
    },
    "& .MuiOutlinedInput-root": {
      color: theme.palette.common.black,
      borderRadius: "50px",
      backgroundColor: theme.palette.common.white,

      "& fieldset": {
        borderRadius: "50px",
      },
      "&:hover fieldset": {
        // borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        // borderColor: "green",
      },
    },
  },
}))(MuiTextField)

const useStyles = makeStyles(theme => {
  return {
    root: {
      paddingTop: theme.spacing(25),
      paddingBottom: theme.spacing(20),
    },
    gridButtons: {
      alignItems: "center",
    },
    rowNews: {
      marginTop: theme.spacing(6),
    },
    teaserTitle: {
      "&:hover": {
        textDecoration: "underline",
      },
      "&:hover:active": {
        textDecoration: "underline",
      },
    },
    teaserDate: {
      marginTop: theme.spacing(1.5),
    },
    teaserContent: {
      marginTop: theme.spacing(1.5),
    },
    imageTeaser: {
      [theme.breakpoints.down("sm")]: {
        maxWidth: theme.spacing(31.25),
        maxHeight: theme.spacing(31.25),
      },
    },
    iconButton: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(0.5),
        padding: "0 5px 0 0",
      },
    },
    icon: {
      width: theme.spacing(6.25),
      height: theme.spacing(6.25),
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(5.25),
        height: theme.spacing(5.25),
      },
    },
    social: {
      fontSize: "18px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
    socialText: {
      textAlign: "center",
      textDecoration: "none !important",
      color: theme.palette.common.white,
      padding: "14px 25px",
      borderRadius: theme.spacing(6.25),
      backgroundColor: "hsl(49deg 100% 50%)",
      "&:hover": {
        backgroundColor: "hsl(49deg 100% 60%)",
      },
      "&:hover:active": {
        backgroundColor: "hsl(49deg 100% 60%)",
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1.75),
      },
    },
    socials: {
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    socialsContainer: {
      width: "100%",
    },
    link: {
      textDecoration: "none !important",
    },
    search: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        marginLeft: "unset",
      },
    },
    searchContainer: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    gridSearchContainer: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    imageTeaserContainer: {
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center",
        justifyContent: "center",
      },
    },
  }
})

const NewsPage = ({ data = [] }) => {
  const classes = useStyles()
  const [value, setValue] = useState("")
  const { allDatoCmsNews = {} } = data
  const news = allDatoCmsNews.nodes || []

  const handleChange = ({ target }) => {
    setValue(target.value)
  }

  const getFilteredNews = (news, value) => {
    if (!news) return []
    return news.filter(item => {
      if (!item) return false
      const searchValue = value && value.toLowerCase()
      const title = item.title && item.title.toLowerCase()
      const description = item.description && item.description.toLowerCase()
      return title.includes(searchValue) || description.includes(searchValue)
    })
  }

  const filteredNews = getFilteredNews(news, value)

  return (
    <Layout bgColor="#B136FF">
      <Seo
        title="Aktualności - Sala zabaw Kakadu"
        description="Aktualności - bieżące informacje o wydarzeniach i imprezach odbywających się w Sali Zabaw Kakadu"
      />
      <Box className={classes.root}>
        <Box position="relative">
          <Cloud position="left" />
          <Container maxWidth="md">
            <Grid container spacing={3} className={classes.gridButtons}>
              <Grid item md={6} sm={12} className={classes.gridSearchContainer}>
                <Box display="flex" className={classes.searchContainer}>
                  <CustomButton size="small" className={classes.button}>
                    Wyszukaj
                  </CustomButton>
                  <TextField
                    id="search"
                    value={value}
                    onChange={handleChange}
                    variant="outlined"
                    className={classes.search}
                    placeholder="wyszukaj.."
                  />
                </Box>
              </Grid>
              <Grid item md={6} sm={12} className={classes.socialsContainer}>
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.socials}
                >
                  <IconButton
                    aria-label="social icon"
                    className={classes.iconButton}
                  >
                    <FacebookIcon className={classes.icon} />
                  </IconButton>

                  <Button
                    size="large"
                    variant="contained"
                    className={classes.socialText}
                    disableElevation
                  >
                    <Typography
                      component="span"
                      variant="h2"
                      className={classes.social}
                    >
                      POLUB NAS NA FACEBOOKU
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            {!(filteredNews || []).length && (value || "").length && (
              <Typography
                variant="h1"
                component="p"
                className={classes.rowNews}
              >
                Brak wyników dla frazy: {value}
              </Typography>
            )}
            {filteredNews.map(item => (
              <Grid
                key={item.id}
                container
                spacing={3}
                className={classes.rowNews}
              >
                <Grid
                  item
                  md={4}
                  sm={12}
                  className={classes.imageTeaserContainer}
                >
                  {item.imageTeaser && (
                    <Link
                      to={`/aktualnosci/${item.slug}`}
                      className={classes.link}
                    >
                      <GatsbyImage
                        alt={`Wpis ${item.title}`}
                        image={getImage(item.imageTeaser)}
                        className={classes.imageTeaser}
                      />
                    </Link>
                  )}
                </Grid>
                <Grid item md={8} sm={12}>
                  <Link
                    to={`/aktualnosci/${item.slug}`}
                    className={classes.link}
                  >
                    <Typography
                      component="p"
                      variant="h1"
                      className={classes.teaserTitle}
                    >
                      {item.title}
                    </Typography>
                  </Link>
                  {/* <Typography variant="body1" className={classes.teaserDate}>
                    {item.date}
                  </Typography> */}
                  <Typography variant="body1" className={classes.teaserContent}>
                    {item.teaser}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Container>
        </Box>
        <Box position="relative">
          <Cloud position="right" />
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query NewsPageQuery {
    allDatoCmsNews {
      nodes {
        id
        title
        slug
        date
        teaser
        description
        seo {
          title
          description
        }
        image {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
        imageTeaser {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            layout: CONSTRAINED
            width: 250
            height: 250
          )
        }
      }
    }
  }
`

export default NewsPage
